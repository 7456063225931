import type { Post } from '@wix/ambassador-blog-v3-post/types';
import { type Request } from '@app/external/post-list-widget/types';
import { type PlatformApi } from '../controller/platform-api';
import getQuery from './get-query';
import { toWixCodePost } from './platformized-post-mapper';
import urlService from './url';

export const fieldsToIncludeQuery = [
  'CONTENT_TEXT',
  'RICH_CONTENT',
  'URL',
  'METRICS',
  'INTERNAL_ID',
]
  .map((field) => `fieldsToInclude=${field}`)
  .join('&');

const getPlatformizedPostBySlug = async ({
  instance,
  slug,
  platformApi,
  request,
}: {
  instance: string;
  slug: string;
  platformApi: PlatformApi;
  request: Request;
}) => {
  try {
    const response = await request<{ post: Post }>(`/posts/slugs/${slug}?${fieldsToIncludeQuery}`, {
      baseUrl: urlService.getAPIUrl(platformApi),
      instance,
    });
    return toWixCodePost(response.post);
  } catch (err) {
    return;
  }
};

const getPlatformizedPostById = async ({
  instance,
  id,
  platformApi,
  request,
}: {
  instance: string;
  id: string;
  platformApi: PlatformApi;
  request: Request;
}) => {
  try {
    const response = await request<{ post: Post }>(`/posts/${id}?${fieldsToIncludeQuery}`, {
      baseUrl: urlService.getAPIUrl(platformApi),
      instance,
    });
    return toWixCodePost(response.post);
  } catch (err) {
    return;
  }
};

const getPlatformizedPosts = async ({
  instance,
  params,
  platformApi,
  request,
}: {
  instance: string;
  params: { [key: string]: number | string };
  platformApi: PlatformApi;
  request: Request;
}) => {
  try {
    const response = await request<{ posts: Post[] }>(
      `/posts?${getQuery(params)}&${fieldsToIncludeQuery}`,
      {
        baseUrl: urlService.getAPIUrl(platformApi),
        instance,
      },
    );
    return response.posts ? response.posts.map(toWixCodePost) : [{}];
  } catch (err) {
    return [];
  }
};
const getPlatformizedLastPost = ({
  instance,
  platformApi,
  request,
}: {
  instance: string;
  platformApi: PlatformApi;
  request: Request;
}) =>
  getPlatformizedPosts({
    instance,
    params: { 'paging.limit': 1 },
    platformApi,
    request,
  }).then((posts) => posts[0]);

export default {
  getPlatformizedPostBySlug,
  getPlatformizedLastPost,
  getPlatformizedPostById,
};
