import { head, keyBy } from 'lodash';
import { getPostFeedPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import {
  FeedType,
  type Post,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { listDemoPosts } from '@wix/ambassador-blog-v3-post/http';
import { Field } from '@wix/ambassador-blog-v3-post/types';
import { createAction, fetchTagsSuccess } from '@wix/communities-blog-client-common';
import { fetchPostSuccess } from '@app/external/common/actions/fetch-post';
import { fetchPostMetadataSuccess } from '@app/external/common/actions/fetch-post-metadata';
import { getLanguageCode } from '@app/external/common/selectors/locale-selectors';
import getEnvironment from '@app/external/common/services/get-environment';
import { normalizePostV3 } from '@app/external/common/services/post-utils';
import { fetchCategoriesSuccess } from '@app/external/common/store/categories/fetch-categories';
import { type NormalizedPost } from '@app/external/common/types';
import { type PostPageThunkAction } from '../types';

export const fetchLastPostRequest = createAction('lastPost/FETCH_REQUEST');
export const fetchLastPostSuccess = createAction(
  'lastPost/FETCH_SUCCESS',
  (payload: NormalizedPost | undefined) => payload,
);
const fetchLastPostFailure = createAction('lastPost/FETCH_FAILURE');

export const fetchLastPost =
  (): PostPageThunkAction<NormalizedPost | undefined> =>
  async (dispatch, getState, { httpClient, platformApi, flowAPI }) => {
    try {
      dispatch(fetchLastPostRequest());

      let lastPostRaw: Post | undefined;

      const response = await httpClient
        .request(
          getPostFeedPage({
            languageCode: getLanguageCode(getState()),
            page: 1,
            pageSize: 1,
            includeContent: true,
            type: FeedType.ALL_POSTS,
            includeInitialPageData: true,
          }),
        )
        .then((res) => res.data.postFeedPage!);

      lastPostRaw = head(response.posts?.posts);

      dispatch(fetchTagsSuccess(response.tags));
      dispatch(fetchCategoriesSuccess(response.categories));

      if (!lastPostRaw && getEnvironment(platformApi).isEditorSegment) {
        lastPostRaw = await httpClient
          .request(
            listDemoPosts({
              language: getLanguageCode(getState()),
              fieldsToInclude: [Field.CONTENT],
              paging: {
                limit: 1,
              },
            }),
          )
          .then((res) => head(res.data.posts as Post[]));
      }

      const categoriesById = keyBy(response.categories, 'id');
      const tagsById = keyBy(response.tags, 'id');

      const lastPost = normalizePostV3(lastPostRaw!);

      const lastPostWithCategoriesAndTags = {
        ...lastPost,
        categories: lastPostRaw?.categoryIds?.map((id) => categoriesById[id]) ?? [],
        tags: lastPostRaw?.tagIds?.map((id) => tagsById[id])?.filter(Boolean) ?? [],
      };

      dispatch(fetchPostMetadataSuccess(lastPostWithCategoriesAndTags));
      dispatch(
        fetchPostSuccess({
          post: lastPostWithCategoriesAndTags,
          postSlug: lastPost.slug!,
        }),
      );
      dispatch(fetchLastPostSuccess(lastPostWithCategoriesAndTags));

      return lastPostWithCategoriesAndTags;
    } catch {
      dispatch(fetchLastPostFailure());
    }
  };
