import { get } from 'lodash';
import {
  FETCH_RECENT_POSTS_FAILURE,
  FETCH_RECENT_POSTS_SUCCESS,
} from '../../../post-page/actions/fetch-recent-posts';
import {
  FETCH_RELATED_POSTS_FAILURE,
  FETCH_RELATED_POSTS_SUCCESS,
} from '../../../post-page/actions/fetch-related-posts';
import { FETCH_ARCHIVE_FAILURE, FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import {
  FETCH_CATEGORY_POSTS_FAILURE,
  FETCH_CATEGORY_POSTS_SUCCESS,
} from '../../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_FAILURE, FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import {
  FETCH_HASHTAG_POSTS_FAILURE,
  FETCH_HASHTAG_POSTS_SUCCESS,
} from '../../actions/fetch-hashtag-posts';
import {
  FETCH_LAST_FEATURED_POST_FAILURE,
  FETCH_LAST_FEATURED_POST_SUCCESS,
} from '../../actions/fetch-last-featured-post';
import { FETCH_POST_FAILURE, FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_TAG_POSTS_FAILURE, FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { SAVE_POST_DRAFT_SUCCESS } from '../../actions/save-post-draft-types';
import {
  CLEAN_POST_EDITOR_STATE,
  UPDATE_POST_EDITOR_POST,
} from '../post-editor/post-editor-actions';
import {
  FETCH_SITE_PROPERTIES_FAILURE,
  FETCH_SITE_PROPERTIES_SUCCESS,
} from '../site-properties/site-properties-types';
import { SET_IS_LOADED } from './is-loaded-actions';

export default function isLoaded(state = {}, action) {
  switch (action.type) {
    case SET_IS_LOADED:
      return setIsLoaded(state, action.payload);

    case FETCH_POST_SUCCESS:
    case FETCH_POST_FAILURE:
      return setIsLoaded(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoaded: true,
      });

    case FETCH_LAST_FEATURED_POST_SUCCESS:
    case FETCH_LAST_FEATURED_POST_FAILURE:
      return setIsLoaded(state, {
        entity: 'last-featured-post',
        isLoaded: true,
      });

    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_FEED_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'feed-posts',
        isLoaded: true,
      });

    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'recent-posts',
        id: action.meta.postId,
        isLoaded: true,
      });

    case FETCH_RELATED_POSTS_SUCCESS:
    case FETCH_RELATED_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'related-posts',
        id: action.meta.postId,
        isLoaded: true,
      });

    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'category',
        id: action.meta.categoryId,
        isLoaded: action.type === FETCH_CATEGORY_POSTS_SUCCESS,
      });

    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'tag',
        id: action.meta.tagId,
        isLoaded: action.type === FETCH_TAG_POSTS_SUCCESS,
      });

    case SAVE_POST_DRAFT_SUCCESS:
    case UPDATE_POST_EDITOR_POST:
      return setIsLoaded(state, {
        entity: 'postDraft',
        id: get(action, 'payload.draftKey', null),
        isLoaded: true,
      });

    case CLEAN_POST_EDITOR_STATE:
      return setIsLoaded(state, {
        entity: 'postDraft',
        id: get(action, 'payload', null),
        isLoaded: false,
      });

    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_ARCHIVE_FAILURE:
      return setIsLoaded(state, {
        entity: 'archive',
        isLoaded: true,
      });

    case FETCH_HASHTAG_POSTS_SUCCESS:
    case FETCH_HASHTAG_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'hashtag-posts',
        isLoaded: true,
      });

    case FETCH_SITE_PROPERTIES_SUCCESS:
    case FETCH_SITE_PROPERTIES_FAILURE:
      return setIsLoaded(state, {
        entity: 'site-properties',
        isLoaded: true,
      });

    default:
      return state;
  }
}

function setIsLoaded(state, { entity, id = '', isLoaded }) {
  const entityState = id
    ? {
        ...state[entity],
        [id]: isLoaded,
      }
    : isLoaded;

  return {
    ...state,
    [entity]: entityState,
  };
}
